/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Text, Title, ColumnCover, ColumnDiv, Subtitle, SeparateLine, SeparateLineWrap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="--menu pb--08 pt--08" style={{"backgroundColor":"rgba(255,255,255,1)"}} menu={true}>
          
          <Menu className="--right" style={{"maxWidth":1310}} fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box --center" style={{"maxWidth":310}}>
              
              <Image style={{"maxWidth":142}} alt={""} src={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png 860w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=2000x_.png 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style4 --right">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kurzy"} target={""} content={"Kurzy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/zajezdy"} target={""} content={"Zájezdy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/obchod"} target={""} content={"Obchod"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/bazen"} target={""} content={"Bazén"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/klub"} target={""} content={"Klub"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/o-nas"} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/fotogalerie"} target={""} content={"Fotogalerie"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kontakt"} target={""} content={"Kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="css-p775ns --center --parallax" style={{"paddingTop":0,"marginBottom":0,"paddingBottom":19}} name={"sef2q21532j"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/13078/0e15ed9c028a4ac0adfe7b40ad8879c6_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13078/0e15ed9c028a4ac0adfe7b40ad8879c6_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/13078/0e15ed9c028a4ac0adfe7b40ad8879c6_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13078/0e15ed9c028a4ac0adfe7b40ad8879c6_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/13078/0e15ed9c028a4ac0adfe7b40ad8879c6_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/13078/0e15ed9c028a4ac0adfe7b40ad8879c6_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13078/0e15ed9c028a4ac0adfe7b40ad8879c6_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13078/0e15ed9c028a4ac0adfe7b40ad8879c6_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Image style={{"maxWidth":360,"marginTop":3,"paddingTop":18}} src={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png 860w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=2000x_.png 2000w"}>
              </Image>

              <Text className="text-box ff--3 fs--20" style={{"maxWidth":654,"paddingTop":24}} content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">potápěčské centrum</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"marginTop":0,"paddingTop":0,"paddingBottom":0}} name={"uf7tgw2tos"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--style4 title-box--center ff--3 fs--22" content={"AKTUALITY"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":20,"paddingBottom":29}} name={"ummwsmzibol"} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" style={{"maxWidth":857}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13078/6a5904c0a3d54ad195c04ffff1635149_s=350x_.jpg"} use={"page"} href={"/klub#orcabazar"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/6a5904c0a3d54ad195c04ffff1635149_s=350x_.jpg 350w"} lightbox={false}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="flex--center" style={{"paddingTop":0}}>
                  
                  <ColumnDiv >
                    
                    <Subtitle className="subtitle-box ff--3" style={{"marginTop":0,"paddingTop":0}} content={"<span style=\"font-weight: 700;\"><a href=\"/klub\">Garážový<br></a><a href=\"/klub#orcabazar\">bazar</a></span><br>"}>
                    </Subtitle>

                    <Text className="text-box ff--3" style={{"marginTop":9,"paddingBottom":89}} content={"<span style=\"color: rgb(164, 19, 19); font-weight: bold;\">21. 8. 2024</span><br>"}>
                    </Text>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13078/0ac01727996746978baa73d462db048c_s=860x_.jpg"} use={"page"} href={"/kurzy#advancenitrox"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/0ac01727996746978baa73d462db048c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/0ac01727996746978baa73d462db048c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/0ac01727996746978baa73d462db048c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/0ac01727996746978baa73d462db048c_s=1400x_.jpg 1400w"} lightbox={false}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="flex--center" style={{"paddingTop":0}}>
                  
                  <ColumnDiv >
                    
                    <Subtitle className="subtitle-box ff--3" style={{"marginTop":0,"paddingTop":0}} content={"<a href=\"/kurzy\"><span style=\"font-weight: 700;\">Kurzy potápění<br></span>OWD, AOWD</a><br>"}>
                    </Subtitle>

                    <Text className="text-box ff--3" style={{"marginTop":9,"paddingBottom":89}} content={"<span style=\"color: rgb(164, 19, 19); font-weight: bold;\">od 3. 9. 2024</span><br>"}>
                    </Text>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/13078/3eadd50f1ae14d91931648c65b0d486c_s=860x_.jpg"} use={"page"} href={"/elbasafari#advancenitrox"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/3eadd50f1ae14d91931648c65b0d486c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/3eadd50f1ae14d91931648c65b0d486c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/3eadd50f1ae14d91931648c65b0d486c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/3eadd50f1ae14d91931648c65b0d486c_s=1400x_.jpg 1400w"} lightbox={false}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="flex--center" style={{"paddingTop":0}}>
                  
                  <ColumnDiv >
                    
                    <Subtitle className="subtitle-box ff--3" style={{"marginTop":0,"paddingTop":0}} content={"<a href=\"/elbasafari\"><span style=\"font-weight: 700;\">Elba<br></span><span style=\"font-weight: bold;\">malé safari</span></a><br>"}>
                    </Subtitle>

                    <Text className="text-box ff--3" style={{"marginTop":9,"paddingBottom":89}} content={"<span style=\"color: rgb(164, 19, 19); font-weight: bold;\">13. - 20. 9. 2024</span><br>"}>
                    </Text>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":5,"paddingBottom":8}} name={"hzs9xu7bdes"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":402}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/da3159d7150e41649331b3d9511afa16_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13078/da3159d7150e41649331b3d9511afa16_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/da3159d7150e41649331b3d9511afa16_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/da3159d7150e41649331b3d9511afa16_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/da3159d7150e41649331b3d9511afa16_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"marginTop":0,"paddingTop":0,"paddingBottom":3,"backgroundColor":"rgba(165,190,203,1)"}} name={"olewgqfz6kh"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center ff--3" content={"Kurzy potápění"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":11,"paddingBottom":0}} name={"e6k88ofbjpf"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--top" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="--right" style={{"maxWidth":583}}>
              
              <Image style={{"maxWidth":76}} src={"https://cdn.swbpg.com/t/13078/575a95daa112484f80732573ac5d72fb_s=350x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/575a95daa112484f80732573ac5d72fb_s=350x_.jpg 350w"}>
              </Image>

              <Text className="text-box text-box--center" style={{"maxWidth":611,"marginTop":8,"paddingLeft":0,"paddingRight":0}} content={"<span style=\"font-family: &quot;IBM Plex Sans&quot;, sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: -0.32px; text-align: left;\">Nově je možné absolvovat kurz pro začátečníky s tlumočením do znakového jazyka.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":17,"paddingBottom":8}} name={"kgitiz2ceo"}>
          
          <ColumnWrap className="column__flex --left el--3" style={{"maxWidth":827}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4" style={{"maxWidth":655}} src={"https://cdn.swbpg.com/t/13078/168e8afcf3c94afdaff32c61d7c28154_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/168e8afcf3c94afdaff32c61d7c28154_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/168e8afcf3c94afdaff32c61d7c28154_s=660x_.jpg 660w"} position={null}>
              </Image>

              <Title className="title-box ff--3 fs--26" style={{"marginTop":10}} content={"Zkušební ponor"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467,"marginTop":14}} content={"<span style=\"color: rgb(0, 0, 0);\">Exkurze s přístrojem v bazénu Slavia je možný každé úterý (září - červen) od 21 do 22 hodin. V případě zakoupení kurzu pro začátečníky Vám bude cena exkurze odečtena od ceny kurzu.</span><br><br>Nejbližší termín: <span style=\"color: rgb(0, 0, 0);\">kdykoli po domluvě s instruktorem</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 ff--3" style={{"marginTop":13}} use={"page"} href={"/kurzy#ponornazkousku"} content={"info"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" style={{"maxWidth":655}} src={"https://cdn.swbpg.com/t/13078/d9ed3ea97e804943875bccc615eeda8e_s=350x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/d9ed3ea97e804943875bccc615eeda8e_s=350x_.jpg 350w"} position={null}>
              </Image>

              <Title className="title-box ff--3 fs--26" style={{"marginTop":10}} content={"Kurz pro začátečníky"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467,"marginTop":14}} content={"<span style=\"color: rgb(0, 0, 0);\">Rekreační potápění s přístrojem v systémech IANTD (Open Water Diver), nebo CMAS (P*), do hloubky 18 m / 20 m.<br></span><br>Nejbližší termín: <span style=\"color: rgb(0, 0, 0);\">3. 9. 2024</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 ff--3" style={{"marginTop":12}} use={"page"} href={"/kurzy#owd"} content={"info"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" style={{"maxWidth":655}} src={"https://cdn.swbpg.com/t/13078/b6e58dd7a42e46408045b4498907c78a_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/13078/b6e58dd7a42e46408045b4498907c78a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/b6e58dd7a42e46408045b4498907c78a_s=660x_.jpg 660w"} position={null}>
              </Image>

              <Title className="title-box ff--3 fs--26" style={{"marginTop":10}} content={"Kurz pro pokročilé"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467,"marginTop":14}} content={"<span style=\"color: rgb(0, 0, 0);\">Potápění s přístrojem do hloubky 30 m v systémech IANTD (Advanced Open Water Diver), nebo CMAS (P**).</span><br><br>Nejbližší termín: <span style=\"color: rgb(0, 0, 0);\">3. 9.</span>&nbsp;<span style=\"color: rgb(0, 0, 0);\">2024</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 ff--3" style={{"marginTop":10}} use={"page"} href={"/kurzy#aowd"} content={"info"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine style={{"paddingTop":0,"paddingBottom":0}} name={"bvn821efk3j"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center" style={{"paddingTop":7,"paddingBottom":4,"backgroundColor":"rgba(165,190,203,1)"}} name={"olewgqfz6kh"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"paddingTop":0}}>
              
              <Title className="title-box title-box--center ff--3" content={"Zájezdy"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":24,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --left el--4" style={{"maxWidth":1319,"paddingLeft":0,"marginBottom":10,"paddingRight":0,"paddingBottom":0}} columns={"4"}>
            
            <ColumnWrapper className="--shadow2">
              
              <Title className="title-box title-box--center ff--3 fs--30" content={"<a href=\"/ciernavoda\">Slovensko<br>Čierna Voda<br></a><br>"}>
              </Title>

              <Image className="--shape4" style={{"maxWidth":655,"marginTop":29}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13078/16156da7a7744c8c8dd36e2bccca957c_s=860x_.jpg"} use={"page"} href={"/ciernavoda"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/16156da7a7744c8c8dd36e2bccca957c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/16156da7a7744c8c8dd36e2bccca957c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/16156da7a7744c8c8dd36e2bccca957c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/16156da7a7744c8c8dd36e2bccca957c_s=1400x_.jpg 1400w"} lightbox={false} position={null}>
              </Image>

              <Text className="text-box text-box--center ff--3" style={{"maxWidth":467}} content={"Termín 2024<br><span style=\"color: rgb(5, 5, 5);\">8. - 11. 8.<br></span><br>&nbsp;Cena: <span style=\"color: rgb(0, 0, 0);\">2 150&nbsp;Kč&nbsp;<br><br>Bohatě zarybněné jezero s vyvázanými atrakcemi pro potápěče, plážová zóna s občerstvením a relax u vody.<br><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shadow2">
              
              <Title className="title-box title-box--center ff--3 fs--30" content={"<a href=\"/elbasafari\">Itálie&nbsp;<br>ostrov&nbsp;Elba<br>malé safari</a><br>"}>
              </Title>

              <Image className="--shape4" style={{"maxWidth":655,"marginTop":29}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13078/2d47fffd236043e9b92cde972fec0f92_s=660x_.jpg"} use={"page"} href={"/elbasafari"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/2d47fffd236043e9b92cde972fec0f92_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/2d47fffd236043e9b92cde972fec0f92_s=660x_.jpg 660w"} lightbox={false} position={null}>
              </Image>

              <Text className="text-box text-box--center ff--3" style={{"maxWidth":467}} content={"Termín 2024<br><span style=\"color: rgb(5, 5, 5);\">13. - 20. 9.</span><br><span style=\"color: rgb(25, 163, 38);\">volná 3 místa</span><br>&nbsp;Cena: <span style=\"color: rgb(0, 0, 0);\">24 800 Kč&nbsp;<br><br>Malý kolektiv, skromnější ubytování, ale přátelská atmosféra a lokality, na které se ze břehu jen tak nepodíváte.<br><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shadow2">
              
              <Title className="title-box title-box--center ff--3 fs--30" content={"<a href=\"/pagkustici\">Chorvatsko<br>ostrov Pag<br>Kustići</a><br>"}>
              </Title>

              <Image className="--shape4" style={{"maxWidth":655,"marginTop":29}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13078/d1303ac6f63241808484209e66b7a072_s=350x_.jpg"} use={"page"} href={"/pagkustici"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/d1303ac6f63241808484209e66b7a072_s=350x_.jpg 350w"} lightbox={false} position={null}>
              </Image>

              <Text className="text-box text-box--center ff--3" style={{"maxWidth":467}} content={"Podzimní termín 2024<br><span style=\"color: rgb(5, 5, 5);\">11. - 15. 10.</span><br><br>&nbsp;Cena: <span style=\"color: rgb(0, 0, 0);\">6 050 Kč</span><br><span style=\"color: rgb(201, 33, 33);\">Volno již jen pro kurzisty</span><span style=\"color: rgb(0, 0, 0);\">&nbsp;<br><br>Potápění v Jaderském moři na ostrově PAG s možností dokončit praktickou část všech kurzů potápění.<br><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shadow2">
              
              <Title className="title-box title-box--center ff--3 fs--30" content={"<a href=\"/egypt-safari\">Egypt - safari<br>&nbsp;Daedalus<br>Elphinstone</a><br>"}>
              </Title>

              <Image className="--shape4" style={{"maxWidth":655,"marginTop":29}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13078/593f30477d844f858edd78b49d152784_s=660x_.jpg"} use={"page"} href={"/egypt-safari"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/593f30477d844f858edd78b49d152784_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/593f30477d844f858edd78b49d152784_s=660x_.jpg 660w"} lightbox={false} position={null}>
              </Image>

              <Text className="text-box text-box--center ff--3" style={{"maxWidth":467}} content={"Termín 2024<br><span style=\"color: rgb(5, 5, 5);\">23. - 30. 11.<br></span><span style=\"color: rgb(47, 200, 35);\">volných 5 míst</span><br>&nbsp;Cena: <span style=\"color: rgb(0, 0, 0);\">37 750 Kč<br><br>Potápění na útesech v Rudém moři. Safari za korálovými zahradami, hejny dravých ryb a žraloky.<br><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shadow2">
              
              <Title className="title-box title-box--center ff--3 fs--30" content={"<a href=\"/mexiko\">Mexiko<br>Yucatán<br>Tulum</a><br>"}>
              </Title>

              <Image className="--shape4" style={{"maxWidth":655,"marginTop":29}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13078/d6bee8af36cc450fb13b11c1b823249e_s=350x_.jpg"} use={"page"} href={"/mexiko"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/d6bee8af36cc450fb13b11c1b823249e_s=350x_.jpg 350w"} lightbox={false} position={null}>
              </Image>

              <Text className="text-box text-box--center ff--3" style={{"maxWidth":467}} content={"Termín 2025<br><span style=\"color: rgb(5, 5, 5);\">24. 2. - 10. 3.<br>10. 3. - 24. 3.<br></span><br>&nbsp;Cena nepotápěči: <span style=\"color: rgb(0, 0, 0);\">22&nbsp;125 Kč</span><br>Cena potápěči: <span style=\"color: rgb(0, 0, 0);\">42 380 Kč<br><br>Cenoty a jeskynní systémy Yucatánského poloostrova.&nbsp;<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shadow2">
              
              <Title className="title-box title-box--center ff--3 fs--30" content={"<a href=\"/shagra\">Egypt<br>Marsa<br>Shagra</a><br>"}>
              </Title>

              <Image className="--shape4" style={{"maxWidth":655,"marginTop":29}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/13078/e13df1046def40e38551063630798478_s=860x_.jpg"} use={"page"} href={"/shagra"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13078/e13df1046def40e38551063630798478_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/e13df1046def40e38551063630798478_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/e13df1046def40e38551063630798478_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/e13df1046def40e38551063630798478_s=1400x_.jpg 1400w"} lightbox={false} position={null}>
              </Image>

              <Text className="text-box text-box--center ff--3" style={{"maxWidth":467}} content={"Termín 2025<br><span style=\"color: rgb(5, 5, 5);\">20. - 27. 4.<br></span><br>&nbsp;Cena: <span style=\"color: rgb(0, 0, 0);\">upravujeme<br><br>Neomezené potápění ze břehu, výjezdy na vzdálenější lokality a plná penze v čistém eko-resortu.<br><br></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1b4itn5" style={{"marginTop":17,"paddingTop":30,"paddingBottom":7.6171875}} layout={"l1"} css={css`
      
    background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 title-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Orca diving, s.r.o.</span>"}>
              </Title>

              <Text className="text-box ff--3 text-box--invert" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"color: rgb(0, 0, 0);\">Ledařská 433/9, Praha 4, 147 00, Česká Republika</span>"}>
              </Text>

              <Image style={{"maxWidth":236,"marginTop":16.6875,"paddingTop":0}} alt={""} src={"https://cdn.swbpg.com/o/13078/000a415e28ce44c5972c4f22d2c183db.PNG"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""} content={null}>
              </Image>

              <Image style={{"maxWidth":36,"marginTop":0,"paddingTop":10,"paddingBottom":0}} alt={""} src={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png"} svg={false} url={"https://www.facebook.com/orcadivingpotapeni"} href={"https://www.facebook.com/orcadivingpotapeni"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png 350w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}